import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Grid } from "../components/grid/grid"
import Subfooter from "../components/footer/subfooter"
import { LinkBody } from "../components/button/button"


const PrivacyPage = () => (
  <Layout>
    <SEO title="Política de privacidad" />

    <Container>
      <Grid>
        <div class="l-start-1 l-end-11 m-start-1 m-end-5">
          <h1>Política de privacidad</h1>
        
          <h2 class="margintop2">Acerca de esta política</h2>
          <p>A través de la siguiente Política, se regula la política de privacidad y cookies de la web www.parquick.com, bajo la titularidad de Parquick S.L. (en adelante, «Parquick»), CIF B67483073, con dirección Custa C 17 s/n Puerta 10 Edificio Leiro (Concentre), 08480, L’Ametlla del Vallès, Barcelona, España.</p>
          <p>Parquick te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios y clientes que puedan ser recabados por la navegación o contratación de servicios a través del sitio Web www.parquick.com.</p>
          <p>En este sentido, el Titular garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 15⁄1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), y al Real Decreto 1720⁄2007, de 21 de diciembre, conocido como el Reglamento de desarrollo de la LOPD. Cumple también con el Reglamento (UE) 2016⁄679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD).</p>
          <p>El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las condiciones incluidas en el Aviso Legal.</p>
          
          <h2 class="margintop2">Principios aplicados en el tratamiento de datos</h2>
          <p>En el tratamiento de tus datos personales, el Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:</p>
          <ul class="margintop1">
            <li>Principio de licitud, lealtad y transparencia: El Titular siempre requerirá el consentimiento para el tratamiento de tus datos personales que puede ser para uno o varios fines específicos sobre los que te informará previamente con absoluta transparencia.</li>
            <li>Principio de minimización de datos: El Titular te solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita.</li>
            <li>Principio de limitación del plazo de conservación: Los datos se mantendrán durante el tiempo estrictamente necesario para el fin o los fines del tratamiento. El Titular te informará del plazo de conservación correspondiente según la finalidad. En el caso de suscripciones, el Titular revisará periódicamente las listas y eliminará aquellos registros inactivos durante un tiempo considerable.</li>
            <li>Principio de integridad y confidencialidad: Tus datos serán tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada. Debes saber que el Titular toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.</li>
          </ul>

          <h2 class="margintop2">Obtención de datos personales</h2>
          <p>Para navegar por www.parquick.com no es necesario que facilites ningún dato personal. Los casos en los que sí proporcionas tus datos personales son los siguientes:</p>
          <ul class="margintop1">
            <li>Al enviar un correo electrónico.</li>
            <li>Al inscribirte en un formulario de suscripción.</li>
          </ul>

          <h2 class="margintop2">Tus derechos</h2>
          <p>El Titular te informa que sobre tus datos personales tienes derecho a:</p>
          <ul class="margintop1">
            <li>Solicitar el acceso a los datos almacenados.</li>
            <li>Solicitar una rectificación o la cancelación.</li>
            <li>Solicitar la limitación de su tratamiento.</li>
            <li>Oponerte al tratamiento.</li>
            <li>Solicitar la portabilidad de tus datos.</li>
          </ul>
          <p>El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente al Titular, lo que significa que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento puede dirigirse al Titular y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, solicitar la portabilidad de sus datos personales, oponerse al tratamiento, limitar su uso o solicitar la cancelación de esos datos en los ficheros del Titular.</p>
          <p>Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a info@parquick.com junto con la prueba válida en derecho.</p>
          <p>Tienes derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.</p>
        
          <h2 class="margintop2">Finalidad del tratamiento de datos personales</h2>
          <p>Cuando te conectas al sitio Web para mandar un correo al Titular, o envías un formulario, estás facilitando información de carácter personal de la que el responsable es el Titular. Esta información puede incluir datos de carácter personal como pueden ser tu dirección IP o dirección de correo electrónico. Al facilitar esta información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por www.parquick.com , sólo como se describe en el Aviso Legal y en la presente Política de Privacidad.</p>
          <p>Los datos personales y la finalidad del tratamiento por parte del Titular es diferente según el sistema de captura de información:</p>
          <ul class="margintop1">
            <li>Formularios de contacto: El Titular solicita datos personales como la dirección de correo electrónico. Por ejemplo, el Titular utiliza esos datos para dar respuesta a tus mensajes, dudas, quejas, comentarios o inquietudes que puedas tener relativas a la información incluida en el sitio Web, los servicios que se prestan a través del sitio Web, el tratamiento de tus datos personales, cuestiones referentes a los textos legales incluidos en el sitio Web, así como cualquier otra consulta que puedas tener y que no esté sujeta a las condiciones del sitio Web o de la contratación.</li>
            <li>Formularios de suscripción a contenidos: El Titular solicita la dirección de correo electrónico para gestionar la lista de suscripciones, enviar boletines, promociones y ofertas especiales. Los datos que facilites al Titular estarán ubicados en los servidores de Google.</li>
          </ul>
          <p>Existen otras finalidades por las que el Titular trata tus datos personales:</p>
          <ul class="margintop1">
            <li>Para garantizar el cumplimiento de las condiciones recogidas en el Aviso Legal y en la ley aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayuden a este sitio Web a garantizar la confidencialidad de los datos personales que recoge.</li>
            <li>Para apoyar y mejorar los servicios que ofrece este sitio Web.</li>
            <li>Para analizar la navegación. El Titular recoge otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en tu ordenador cuando navegas por el sitio Web cuyas caracterísiticas y finalidad están detalladas en la Política de Cookies.</li>
            <li>Para gestionar las redes sociales. el Titular tiene presencia en redes sociales. Si te haces seguidor en las redes sociales del Titular el tratamiento de los datos personales se regirá por este apartado, así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y que has aceptado previamente.</li>
          </ul>
          <p>Puedes consultar las políticas de privacidad de las principales redes sociales en estos enlaces:</p>
          <p>El Titular tratará tus datos personales con la finalidad de administrar correctamente su presencia en la red social, informarte de sus actividades, productos o servicios, así como para cualquier otra finalidad que las normativas de las redes sociales permitan.</p>
          <p>En ningún caso el Titular utilizará los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.</p>
        
          <h2 class="margintop2">Seguridad de los datos personales</h2>
          <p>Para proteger tus datos personales, el Titular toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso indebido, divulgación, alteración o destrucción de los mismos.</p>
          <p>El sitio Web está alojado en Raiola Networks. La seguridad de tus datos está garantizada, ya que toman todas las medidas de seguridad necesarias para ello. Puedes consultar su política de privacidad para tener más información.</p>

          <h2 class="margintop2">Contenido de otros sitios web</h2>
          <p>Las páginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si hubieras visitado la otra web.</p>
          <p>Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies, incrustar un código de seguimiento adicional de terceros, y supervisar tu interacción usando este código.</p>

          <h2 class="margintop2">Política de Cookies</h2>
          <p>Para que este sitio Web funcione correctamente necesita utilizar cookies, que es una información que se almacena en tu navegador web.</p>
          <p>En el apartado de <LinkBody to="/politica-de-cookies/">Política de Cookies</LinkBody> puedes consultar toda la información relativa a la política de recogida, la finalidad y el tratamiento de las cookies.</p>
        </div>
      </Grid>
    </Container>
    <Subfooter />
  </Layout>
)

export default PrivacyPage
